<template>
  <v-row
    class="hq-tasks-layout px-md-4" align="start" justify="start"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <hq-task-actions :api-key="apiKey" />
    <add-task-button :api-key="apiKey" task-mode="hq" />
    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" class="mt-10">
      <hq-tasks-top-stats :api-key="apiKey" />
    </v-col>
    <v-col v-if="isLoadingTasks" cols="12">
      <v-skeleton-loader
        v-for="i in 3" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
    <v-col v-else cols="12">
      <v-row
        align="start" justify="start" no-gutters
        class="hq-task-account-list"
      >
        <v-col v-for="account in accountList" :key="account.account_api_key" cols="12">
          <hq-tasks-account-list-single-account :api-key="apiKey" :account="account" />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="isLoadingTasksNextPage" cols="12">
      <v-skeleton-loader
        v-for="i in 3" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
    <v-col v-if="hasMoreTasksToLoad" v-intersect.once="onIntersect" cols="12" />
  </v-row>
</template>
<script>
import HqTasksTopStats from '@/components/hq_tasks/hq_tasks_top_stat/HqTasksTopStats.vue'
import HqTasksAccountListSingleAccount from '@/components/hq_tasks/HqTasksAccountListSingleAccount.vue'
import HqTaskActions from '@/components/hq_tasks/HqTaskActions.vue'
import AddTaskButton from '@/components/tasks/AddTaskButton.vue'
import useHQDailyTasks from '@/composables/useHQDailyTasks.js'

import { dateToISO8601String } from '@/helpers/DateTime.js'

import { isRtl } from '@/i18n'

import { watch } from 'vue'
export default {
  components: {
    'hq-task-actions': HqTaskActions,
    'hq-tasks-top-stats': HqTasksTopStats,
    'hq-tasks-account-list-single-account': HqTasksAccountListSingleAccount,
    'add-task-button': AddTaskButton
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    clientDate: {
      type: Date,
      required: true
    }
  },
  setup (props) {
    const { accountList, clientDate, isLoadingTasks, hasMoreTasksToLoad, isLoadingTasksNextPage, initPageData, changeDate, loadNextPage } = useHQDailyTasks(props.apiKey)

    const onIntersect = loadNextPage
    initPageData(props.clientDate)

    watch(() => props.clientDate, (newDate) => {
      if (newDate === null) return
      if (dateToISO8601String(newDate) === dateToISO8601String(clientDate.value)) return

      changeDate(newDate)
    })
    return {
      isRtl,
      accountList,
      isLoadingTasks,
      hasMoreTasksToLoad,
      isLoadingTasksNextPage,
      onIntersect
    }
  }
}
</script>
