<template>
  <v-row
    align="start" justify="start" class="single-task py-2 px-3"
    :class="{ 'changin-status': pendingStatusChange }"
  >
    <single-task-side-part
      :task="task"
      :disabled="disabled"
      :allow-toggle-complete="allowToggleComplete"
    />
    <v-col
      class="flex-grow-1 flex-shrink-0 py-2 ppe-0" :class="{'pointer' : !crossAccountView}"
      @click="toggleExpanded"
    >
      <single-task-top-part
        :task="task"
        :is-expanded="isExpanded"
        :client-date="clientDate"
        :task-enabled-options="taskEnabledOptions"
        :cross-account-view="crossAccountView"
        :hq-view="hqView"
      />
      <single-task-bottom-part
        v-if="isExpanded"
        :task="task"
      />
    </v-col>
  </v-row>
</template>
<script>
import SingleTaskSidePart from '@/components/tasks/tasks_list/single_task/SingleTaskSidePart.vue'
import SingleTaskTopPart from '@/components/tasks/tasks_list/single_task/SingleTaskTopPart.vue'
import SingleTaskBottomPart from '@/components/tasks/tasks_list/single_task/SingleTaskBottomPart.vue'

import { ref, computed } from 'vue'
export default {
  components: {
    'single-task-side-part': SingleTaskSidePart,
    'single-task-top-part': SingleTaskTopPart,
    'single-task-bottom-part': SingleTaskBottomPart
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    clientDate: {
      type: String,
      required: true
    },
    taskEnabledOptions: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowToggleComplete: {
      type: Function,
      required: false,
      default: null
    },
    crossAccountView: {
      type: Boolean,
      default: false
    },
    hqView: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const isExpanded = ref(false)

    // Methods
    const toggleExpanded = () => {
      if (props.crossAccountView) return
      isExpanded.value = !isExpanded.value
    }

    // Computed
    const pendingStatusChange = computed(() => props.task.operationRef.pendingStatusChange)
    return {
      isExpanded,
      pendingStatusChange,
      toggleExpanded
    }
  }
}
</script>
<style lang="scss">
.single-task {
  background-color: white;
  border-right: 1px solid #DADADA;
  border-left: 1px solid #DADADA;
  margin-top: 0 !important;
  margin-bottom: 0;
  input[type="file"] {
    display: none;
  }
  .task-tags > span, .v-chip {
    margin-right: 4px;
  }
  .task-tags > *:last-child {
    margin-right: 0;
  }
  &.changin-status {
    animation: fadeOutAndMoveDown 1500ms ease-in-out forwards;
  }
  .spacer {
    width: 36px;
  }
  .expand-text {
    white-space: nowrap;
    font-size: 13px;
    font-weight: 600;
    color: #616365;
  }
  .expand-icon {
    transition: transform 0.3s ease;
    &.rotated {
      transform: rotate(180deg);
    }
  }
  .account-image-preview {
    border-radius: 50%;
  }

  .image-preview {
    border-radius: 12px;
  }

  .job-divider {
    height: 15px;
    background-color: #C9C9C9;
  }
  @keyframes fadeOutAndMoveDown {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(calc(100vh - 200px));
    }
  }

  .side-part {
    border-radius: 8px;
    align-items: center;
    .color-line {
      height: 100%;
      width: 6px;
      border-radius: 4px;
    }
    &.reported {
      background-color: unset;
      align-items: start;
    }

    .side-content {
      height: 100%;
      width: 100%;
      display: flex;
      padding: 0 5px;
      align-items: center;
      justify-content: center;

      .v-icon {
        padding: 0 5px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .expand-text {
      display: none;
    }
    .spacer {
      display: none;
    }
  }
}
.rtl {
    .single-task {
      .task-tags > span, .v-chip {
      margin-left: 4px;
    }
    .task-tags > *:last-child {
      margin-left: 0;
    }
  }
}
</style>
