<template>
  <v-row
    align="center" no-gutters justify="space-between"
    class="account-rating my-0 px-2 color20"
    :style="{ backgroundColor }"
  >
    <v-col class="flex-grow-0 flex-shrink-1">
      <v-avatar
        class="mme-2"
        size="32"
      >
        <v-img :src="accountImage" />
      </v-avatar>
    </v-col>
    <v-col class="flex-grow-1 flex-shrink-0 t-700-16">
      {{ account.name }}
      <span v-if="showDistrict">
        <v-divider vertical class="area-divider" />
        {{ account.district_name }}
      </span>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 text-no-wrap t-700-13">
      <span class="color1">{{ accountRating.done_tasks }}</span> / {{ accountRating.total_tasks }}
    </v-col>
  </v-row>
</template>
<script>

import { isString } from '@/helpers/Utils.js'
import { computed } from 'vue'

export default {
  props: {
    accountRating: {
      type: Object,
      required: true
    },
    backgroundColor: {
      type: String,
      default: '#EAEFFF'
    }
  },
  setup (props) {
    const account = computed(() => props.accountRating.account)
    const showDistrict = computed(() => isString(account.value.district_name) && account.value.district_name.length > 0)
    const accountImage = computed(() => isString(account.value.custom_store_image)
      ? account.value.custom_store_image
      : account.value.store_image)
    return {
      account,
      accountImage,
      showDistrict
    }
  }
}
</script>
<style lang="scss">
.hq-task-stats {
  .account-rating {
    height: 55px;
    border-radius: 38px;
  }
  .area-divider {
    width: 1px;
    height: 20px;
    margin: 0 2px;
    background-color: #111;
  }
}
</style>
