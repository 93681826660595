<template>
  <hq-tasks-top-stats-loading v-if="isLoadingStats" />
  <v-row v-else class="hq-task-stats">
    <v-col cols="5" class="pa-5">
      <v-row no-gutters align="start" justify="start">
        <v-col cols="6">
          <span class="graph-wrapper text-center d-inline-block">
            <canvas ref="statChart" class="doughnut-chart" />
            <span class="stats-total t-800-22 color20">
              {{ totalTasks }}
              <div>
                {{ $t('tasks') }}
              </div>
            </span>
          </span>
          <div class="mt-4 t-500-14" style="margin-left: 25%;">
            <span class="done-circle mme-1 bg-color1" />
            {{ totalDoneTasks }} {{ $t('done') }}
          </div>
          <div class="t-500-14" style="margin-left: 25%;">
            <span class="not-done-circle mme-1 bg-color19" />
            {{ totalNotDoneTasks }} {{ $t('not_done') }}
          </div>
        </v-col>
        <v-col
          cols="12" lg="6" md="7"
          class="align-self-stretch d-flex flex-column justify-space-between py-3"
        >
          <div
            v-for="tag in reportTags" :key="tag.name"
            class="chip-wrapper"
          >
            <hq-tasks-top-stats-chip :tag="tag" />
          </div>
          <div class="chip-wrapper">
            <hq-tasks-top-stats-chip :tag="untaggedTasks" :show-icon="false" />
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1" class="text-center">
      <v-divider
        class="my-0 mx-0 divider-vertical"
        vertical
      />
    </v-col>

    <v-col cols="6" class="py-5">
      <div class="t-500-22">
        {{ $t('hq_tasks_page.account_rating') }}
      </div>
      <v-row
        align="start" justify="start"
      >
        <v-col cols="12" lg="6">
          <div class="t-400-16 color2 mt-2">
            {{ $t('hq_tasks_page.top_3') }}
            <v-icon>
              north_east
            </v-icon>
          </div>

          <hq-tasks-top-stats-account-rating
            v-for="account in topAccounts"
            :key="account.api_key" class="mt-4"
            :account-rating="account"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <div class="t-400-16 color2 mt-2">
            {{ $t('hq_tasks_page.bottom_3') }}
            <v-icon>
              south_east
            </v-icon>
          </div>
          <hq-tasks-top-stats-account-rating
            v-for="account in bottomAccounts" :key="account.api_key"
            class="mt-4"
            :account-rating="account"
            background-color="#F4F4F4"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import HqTasksTopStatsLoading from '@/components/hq_tasks/hq_tasks_top_stat/HqTasksTopStatsLoading.vue'
import HqTasksTopStatsChip from '@/components/hq_tasks/hq_tasks_top_stat/HqTasksTopStatsChip.vue'
import HqTasksTopStatsAccountRating from '@/components/hq_tasks/hq_tasks_top_stat/HqTasksTopStatsAccountRating.vue'

import useHQDailyTasks from '@/composables/useHQDailyTasks.js'

import { tagColors, translateReportTag } from '@/store/modules/OperationSchedule.js'

import Chart from 'chart.js/auto'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed, watch, nextTick } from 'vue'

const validReportTags = ['monitor', 'report', 'procedure']

const chartDefaultOptions = {
  type: 'doughnut',
  data: {
    datasets: [{
      labels: [i18n.t('done'), i18n.t('not_done')],
      data: [0, 0],
      backgroundColor: [
        '#0040FF',
        '#D4D4D4'
      ]
    }]
  },
  options: {
    cutout: '80%',
    responsive: true,
    plugins: {
      tooltip: {
        rtl: isRtl,
        callbacks: {
          label: function (context) {
            return ` ${context.dataset.labels[context.dataIndex]} :  ${context.formattedValue}`
          }
        }
      }
    }
  }
}

export default {
  components: {
    'hq-tasks-top-stats-chip': HqTasksTopStatsChip,
    'hq-tasks-top-stats-loading': HqTasksTopStatsLoading,
    'hq-tasks-top-stats-account-rating': HqTasksTopStatsAccountRating
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { isLoadingStats, taskStats } = useHQDailyTasks(props.apiKey)
    const statChart = ref(null)
    const totalTasks = ref(null)
    const totalDoneTasks = ref(null)
    const totalNotDoneTasks = ref(null)
    let doughnutChart = null

    const setChartData = () => {
      totalTasks.value = taskStats.value.tasks_stats.total_tasks || 0
      totalDoneTasks.value = taskStats.value.finished_tasks_stats.total_tasks || 0
      totalNotDoneTasks.value = totalTasks.value - totalDoneTasks.value
      doughnutChart.data.datasets[0].data = [totalDoneTasks.value, totalNotDoneTasks.value]
      doughnutChart.update()
    }
    // Computed
    const reportTags = computed(() => {
      const totalTasks = taskStats.value.tasks_stats || {}
      const totalDoneTasks = taskStats.value.finished_tasks_stats || {}
      return validReportTags.map((tag) => {
        const key = `tag_${tag}`
        const totalTasksForTag = totalTasks[key] || 0
        const totalDoneTasksForTag = totalDoneTasks[key] || 0
        return {
          totalTasks: totalTasksForTag,
          totalDoneTasks: totalDoneTasksForTag,
          color: tagColors[tag],
          name: tag,
          translatedName: translateReportTag(tag)
        }
      })
    })

    const untaggedTasks = computed(() => {
      const totalTasks = taskStats.value.tasks_stats?.total_untagged_tasks || 0
      const totalDoneTasks = taskStats.value.finished_tasks_stats?.total_untagged_tasks || 0
      return {
        totalTasks,
        totalDoneTasks,
        color: 'white',
        translatedName: i18n.t('untagged')
      }
    })

    const topAccounts = computed(() => taskStats.value.top_accounts_marked || [])
    const bottomAccounts = computed(() => taskStats.value.least_accounts_marked || [])

    watch(() => isLoadingStats.value, (isLoading) => {
      if (isLoading) return

      nextTick(() => {
        const ctx = statChart.value.getContext('2d')
        doughnutChart = new Chart(ctx, chartDefaultOptions)
        setChartData()
      })
    }, { immediate: true })

    return {
      isLoadingStats,
      taskStats,
      statChart,
      totalTasks,
      totalDoneTasks,
      totalNotDoneTasks,
      reportTags,
      untaggedTasks,
      topAccounts,
      bottomAccounts
    }
  }
}
</script>
<style lang="scss">
.hq-task-stats {
  background-color: white;
  border-radius: 16px;
  .chip-wrapper {
    min-height: 60px;
  }
  .graph-wrapper {
    position: relative;
    .doughnut-chart {
      width: 100%;
      height: 100%;
      max-width: 15vw;
    }
    .stats-total {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .done-circle, .not-done-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
