import { dailyStats, indexOperationsByDay } from '@/api_client/CompanyOperationSchedule.js'

import {
  isDateInTheFuture, isDateIsToday, dateToISO8601String, isDateInThePast
} from '@/helpers/DateTime.js'

import UseOperationSchedule from '@/composables/UseOperationSchedule.js'

import { isString, isObject } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import Vue, { ref, computed } from 'vue'

const DEFAULT_PER_PAGE = 100
const DEFAULT_PAGE = 1

const defaultPagination = () => ({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE, total: 0 })

const clientDate = ref(new Date())
const pagination = ref(defaultPagination())
const taskStats = ref({})
const tasksList = ref([])
const filters = ref({})
const accountsData = ref({})
const isLoadingStats = ref(false)
const isLoadingTasks = ref(false)
const isLoadingTasksNextPage = ref(false)
let currentApiKey = null

export default function useHQDailyTasks (apiKey) {
  const resetValues = () => {
    clientDate.value = new Date()
    pagination.value = defaultPagination()
    taskStats.value = {}
    filters.value = {}
    isLoadingStats.value = false
    tasksList.value = []
    accountsData.value = {}
    isLoadingTasks.value = false
    isLoadingTasksNextPage.value = false
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()

    currentApiKey = apiKey
  }

  const setPaginationFromResponse = (responseMeta) => {
    const paginationVal = pagination.value
    paginationVal.total = responseMeta.total
    paginationVal.page = responseMeta.page
    paginationVal.perPage = responseMeta.per_page
  }

  const addAccountsData = (responseMeta) => {
    const newAccountsData = responseMeta.accounts_data
    const accountsDataVal = accountsData.value
    if (!isObject(newAccountsData)) return

    for (const apiKey in newAccountsData) {
      if (!accountsDataVal[apiKey]) {
        Vue.set(accountsDataVal, apiKey, newAccountsData[apiKey])
      }
    }
  }

  const baseRequestParams = () => {
    const requestParams = {
      client_date: clientDateIso.value,
      client_date_is_today: isDateIsToday(clientDate.value),
      client_date_is_future: isDateInTheFuture(clientDate.value)
    }
    return requestParams
  }

  const loadDailyTasks = ({ reset = false } = {}) => {
    if (reset) {
      pagination.value = defaultPagination()
      tasksList.value = []
    }
    if (pagination.value.page > 1) {
      isLoadingTasksNextPage.value = true
    } else {
      isLoadingTasks.value = false
    }
    const requestParams = baseRequestParams()
    requestParams.page = pagination.value.page
    requestParams.per_page = pagination.value.per_page

    indexOperationsByDay(apiKey, requestParams)
      .then(({ data: { data, meta } }) => {
        setPaginationFromResponse(meta)
        addAccountsData(meta)
        const newTasks = data.map((task) => new UseOperationSchedule(task))
        tasksList.value = tasksList.value.concat(newTasks)
      })
      .catch(errHandler)
      .finally(() => {
        isLoadingTasks.value = false
        isLoadingTasksNextPage.value = false
      })
  }
  const loadTaskStats = () => {
    isLoadingStats.value = true
    dailyStats(apiKey, baseRequestParams())
      .then(({ data: { data } }) => (taskStats.value = data))
      .finally(() => (isLoadingStats.value = false))
  }

  // Methods
  const findTask = (taskId) => tasksList.value.find((task) => task.operationRef.operation_id === taskId)
  const applyFilters = () => {
    loadDailyTasks({ reset: true })
  }
  const initPageData = (clientDateVal) => {
    resetValues()
    clientDate.value = clientDateVal

    return Promise.all([
      loadDailyTasks(),
      loadTaskStats()
    ])
  }

  const changeDate = async (newDate) => {
    clientDate.value = newDate
    applyFilters()
  }

  const deleteTask = async (task) => {
    await task.deleteOperation()
    const taskId = task.operationRef.operation_id
    const taskIndex = tasksList.value.findIndex((task) => task.operationRef.operation_id === taskId)
    if (taskIndex > -1) {
      tasksList.value.splice(taskIndex, 1)
    }
    loadTaskStats()
  }
  const loadNextPage = () => {
    if (hasMoreTasksToLoad.value) {
      pagination.value.page++
      loadDailyTasks()
    }
  }

  const afterTaskMarkComplete = loadTaskStats
  const afterTaskMarkUnComplete = loadTaskStats

  // Computed
  const clientDateIso = computed(() => dateToISO8601String(clientDate.value))
  const hasMoreTasksToLoad = computed(() => {
    if (isLoadingTasks.value || isLoadingTasksNextPage.value) return false
    return pagination.value.total > tasksList.value.length
  })

  const tasksDisabled = computed(() => {
    if (dateToISO8601String(new Date()) === clientDateIso.value) return false

    if (isDateInThePast(clientDate.value)) return false
    return true
  })

  const accountList = computed(() => {
    const result = []
    const apiKeys = new Set()
    tasksList.value.forEach((task) => apiKeys.add(task.operationRef.account_api_key))
    for (const value of apiKeys) {
      result.push(accountsData.value[value])
    }
    return result
  })
  return {
    isLoadingStats,
    taskStats,
    accountList,
    tasksList,
    tasksDisabled,
    clientDateIso,
    clientDate,
    isLoadingTasks,
    isLoadingTasksNextPage,
    hasMoreTasksToLoad,
    applyFilters,
    initPageData,
    changeDate,
    deleteTask,
    findTask,
    afterTaskMarkComplete,
    afterTaskMarkUnComplete,
    loadNextPage
  }
}
