<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col class="flex-grow-1 flex-shrink-0">
      <div v-if="showAccountInfo" class="d-flex align-start justify-start">
        <v-img
          :src="task.operationRef.account_image"
          contain height="40" width="40"
          class="pointer grey darken-4 account-image-preview flex-grow-0 flex-shrink-1"
        >
          <template #placeholder>
            <v-row
              align="center" justify="center" class="fill-height"
              no-gutters
            >
              <v-progress-circular indeterminate color="primary" />
            </v-row>
          </template>
        </v-img>
        <span class="t-500-16 color5 pps-2">
          {{ task.operationRef.account_name }}
        </span>
      </div>
      <div v-if="showDescriptionAndType" class="t-500-16 color5">
        {{ descriptionAndType }}
      </div>
      <div>
        <span v-if="hqView" class="t-500-13 color5 mme-1">
          <v-icon class="material-icons-outlined" color="rgba(0, 0, 0, 0.54)">
            how_to_reg
          </v-icon>
          {{ jobName }} <v-divider vertical class="job-divider mx-1" /> {{ jobShift }} ({{ jobShiftPart }})
        </span>
        <single-task-tags :task="task" :cross-account-view="crossAccountView" />
      </div>
      <v-expand-transition>
        <single-task-additional-details
          v-if="isExpanded" :task="task" :cross-account-view="crossAccountView"
          :hq-view="hqView"
          :client-date="clientDate"
        />
      </v-expand-transition>

      <div v-if="crossAccountView" class="mt-1 color5 t-500-13">
        <div v-if="showJobDepartment">
          <v-icon class="material-icons-outlined" color="rgba(0, 0, 0, 0.54)">
            pin_drop
          </v-icon>
          <span>
            {{ task.operationRef.account_job_department_name }}
          </span>
        </div>
        <div>
          <v-icon color="rgba(0, 0, 0, 0.54)">
            person_outline
          </v-icon>
          <span>
            {{ jobName }} {{ jobShift }}
          </span>
        </div>
      </div>
      <div v-if="showImage" class="mt-1">
        <v-img
          :src="task.operationRef.image_url" contain height="65"
          width="65"
          class="pointer grey darken-4 image-preview"
          @click.stop="onImageClick"
        >
          <template #placeholder>
            <v-row
              align="center" justify="center" class="fill-height"
              no-gutters
            >
              <v-progress-circular indeterminate color="primary" />
            </v-row>
          </template>
        </v-img>
      </div>
      <div v-else-if="showImageUploading" class="mt-1">
        <v-row align="center" justify="start" no-gutters>
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </div>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 d-flex align-center">
      <v-chip
        v-if="isReported && !$vuetify.breakpoint.xsOnly" color="#0E4AFD" class="white--text mme-3"
      >
        <v-icon>
          check
        </v-icon>
        <span class="t-400-14">{{ $t('tasks_page.task_done') }}</span>
      </v-chip>
      <span v-if="!crossAccountView">
        <span class="expand-text">
          {{ isExpanded ? $t('tasks_page.less_details') : $t('tasks_page.more_details') }}
        </span>
        <v-icon class="mme-3 expand-icon" :class="{ rotated: isExpanded }">
          keyboard_arrow_down
        </v-icon>
      </span>
      <v-icon
        v-if="showNotification"
        class="pointer mme-3"
        color="#0000008a"
        @click.stop="onNotifyClick"
      >
        notifications
      </v-icon>
      <span v-else class="spacer" />
      <v-badge
        class="mme-3"
        :color="messageCountColor"
        overlap
        :value="hasUnreadChatMessages"
        bordered
        :content="task.operationRef.total_messages_count"
      >
        <v-icon
          color="#0000008a"
          class="pointer material-icons-outlined"
          @click.stop="onChatClick"
        >
          {{ chatMessageIcon }}
        </v-icon>
      </v-badge>
      <single-task-options
        :task="task" :task-enabled-options="taskEnabledOptions" @delete-click="onDeleteClick"
        @copy-click="onCopyClick" @share-click="onShareClick" @edit-click="onEditClick"
        @notify-click="onNotifyClick"
      />
    </v-col>
  </v-row>
</template>
<script>

import SingleTaskOptions from '@/components/tasks/tasks_list/single_task/SingleTaskOptions.vue'
import SingleTaskTags from '@/components/tasks/tasks_list/single_task/SingleTaskTags.vue'
import SingleTaskAdditionalDetails from '@/components/tasks/tasks_list/single_task/SingleTaskAdditionalDetails.vue'
import { translateShift } from '@/store/modules/OrganizationJob.js'
import { translatedOperation as OpTypeTranslatedOperation, ROUTINE_OPERATION } from '@/composables/useOperationType.js'

import { translateShiftPartName } from '@/store/modules/OperationSchedule.js'

import { isString } from '@/helpers/Utils.js'

import {
  EventBus, TASK_DELETE_CLICK, TASK_COPY_CLICK, TASK_SHARE_CLICK, TASK_CHAT_CLICK,
  TASK_EDIT_CLICK, TASK_NOTIFICATION_CLICK, TASK_IMAGE_CLICK
} from '@/EventBus.js'

import { computed } from 'vue'

export default {
  components: {
    'single-task-options': SingleTaskOptions,
    'single-task-tags': SingleTaskTags,
    'single-task-additional-details': SingleTaskAdditionalDetails
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    clientDate: {
      type: String,
      required: true
    },
    taskEnabledOptions: {
      type: Object,
      required: true
    },
    crossAccountView: {
      type: Boolean,
      default: false
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    hqView: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // Methods
    const onDeleteClick = () => EventBus.emit(TASK_DELETE_CLICK, props.task)
    const onCopyClick = () => EventBus.emit(TASK_COPY_CLICK, props.task)
    const onShareClick = () => EventBus.emit(TASK_SHARE_CLICK, props.task)
    const onEditClick = () => EventBus.emit(TASK_EDIT_CLICK, props.task)
    const onNotifyClick = () => EventBus.emit(TASK_NOTIFICATION_CLICK, props.task)
    const onImageClick = () => EventBus.emit(TASK_IMAGE_CLICK, props.task)
    const onChatClick = () => EventBus.emit(TASK_CHAT_CLICK, props.task)

    // Computed
    const opRef = computed(() => props.task.operationRef)
    const isReported = computed(() => props.task.isReported())
    const pendingStatusChange = computed(() => opRef.value.pendingStatusChange)
    const showImage = computed(() => isReported.value && props.task.requireImage() && isString(opRef.value.image_url))
    const showImageUploading = computed(() => isReported.value && props.task.requireImage() && props.task.uploading_image === true)
    const showAccountInfo = computed(() => props.crossAccountView && isString(opRef.value.account_name))
    const showDescriptionAndType = computed(() => !props.crossAccountView && descriptionAndType.value.length > 0)
    const messageCountColor = computed(() => Number.isInteger(opRef.value.unread_messages_count) && opRef.value.unread_messages_count > 0 ? '#EC2328' : 'green')
    const hasUnreadChatMessages = computed(() => Number.isInteger(props.task.operationRef.unread_messages_count) && props.task.operationRef.unread_messages_count > 0)
    const chatMessageIcon = computed(() => {
      if (hasUnreadChatMessages.value) return 'chat_bubble_outline'
      const haveAnyMessages = Number.isInteger(props.task.operationRef.total_messages_count) && props.task.operationRef.total_messages_count > 0
      return haveAnyMessages ? 'mark_chat_read' : 'chat_bubble_outline'
    })

    const showNotification = computed(() => opRef.value.mark_complete_notification || opRef.value.mark_uncomplete_notification)
    const showJobDepartment = computed(() => isString(opRef.value.account_job_department_name) && opRef.value.account_job_department_name.length > 0)

    const jobName = computed(() => opRef.value.account_job_name)
    const jobShift = computed(() => translateShift(opRef.value.account_job_shift))
    const jobShiftPart = computed(() => translateShiftPartName(opRef.value.shift_part))

    const descriptionAndType = computed(() => {
      const description = opRef.value.operation_description || ''
      if (ROUTINE_OPERATION === opRef.value.operation_type) return description

      const translatedOp = OpTypeTranslatedOperation(opRef.value.operation_type)

      if (description.length > 0) return `${translatedOp} - ${description}`

      return translatedOp
    })

    return {
      isReported,
      showDescriptionAndType,
      showNotification,
      showJobDepartment,
      descriptionAndType,
      pendingStatusChange,
      showAccountInfo,
      showImageUploading,
      jobName,
      jobShift,
      jobShiftPart,
      showImage,
      messageCountColor,
      hasUnreadChatMessages,
      chatMessageIcon,
      onDeleteClick,
      onCopyClick,
      onShareClick,
      onEditClick,
      onNotifyClick,
      onImageClick,
      onChatClick
    }
  }
}
</script>
