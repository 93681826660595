import Client from '@/api_client/client.js'

const totalCompanyTasks = (apiKey, params) => {
  const TOTAL_COMPANY_TASKS_PATH = `/accounts/${apiKey}/operation_schedules/total_for_company`
  return Client.put(TOTAL_COMPANY_TASKS_PATH, params)
}

const dailyTaskStats = (apiKey, params) => {
  const TOTAL_COMPANY_TASKS_PATH = `/accounts/${apiKey}/operation_schedules/tasks_daily_stats`
  return Client.put(TOTAL_COMPANY_TASKS_PATH, params)
}

export {
  totalCompanyTasks,
  dailyTaskStats
}
