<template>
  <v-row
    class="single-account-tasks-list"
    align="start"
    justify="start"
    no-gutters
  >
    <v-col
      v-for="task in tasksListForAccount" :key="task.id" cols="12"
      class="mb-2"
    >
      <single-task-layout
        :client-date="clientDateIso"
        :task="task"
        :task-enabled-options="TASK_OPTIONS"
        :disabled="tasksDisabled"
        hq-view
      />
    </v-col>
  </v-row>
</template>
<script>
import SingleTaskLayout from '@/components/tasks/tasks_list/single_task/SingleTaskLayout.vue'

import useHQDailyTasks from '@/composables/useHQDailyTasks.js'

import { computed } from 'vue'

const TASK_OPTIONS = {
  edit: true,
  delete: true,
  share: true,
  notification: true,
  copy: true
}
export default {
  components: {
    'single-task-layout': SingleTaskLayout
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { tasksList, clientDateIso, tasksDisabled } = useHQDailyTasks(props.apiKey)

    const tasksListForAccount = computed(() => {
      const accountApiKey = props.account.api_key
      return tasksList.value.filter(task => task.operationRef.account_api_key === accountApiKey)
    })

    return {
      TASK_OPTIONS,
      tasksListForAccount,
      clientDateIso,
      tasksDisabled
    }
  }
}
</script>
<style lang="scss">
.single-account-tasks-list {
  .single-task {
    border-radius: 16px;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  }
}
</style>
