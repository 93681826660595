<template>
  <div>
    <div v-if="showJobDepartment">
      <v-icon class="material-icons-outlined" color="rgba(0, 0, 0, 0.54)">
        pin_drop
      </v-icon>
      <span class="t-500-13 color5">
        {{ task.operationRef.account_job_department_name }}
      </span>
    </div>
    <div v-if="!crossAccountView">
      <v-icon color="#F5409B">
        schedule
      </v-icon>
      <span class="pps-1 color7 t-400-12">
        {{ frequencyText }}
      </span>
    </div>
    <div v-if="!crossAccountView && showEveryXWeeksStartPeriod" class="my-1">
      <v-icon color="#F5409B">
        calendar_today
      </v-icon>
      <span class="pps-1 color7 t-400-12">
        {{ startPeriodForEveryXWeeksFrequnecy }}
      </span>
    </div>
    <div v-if="showCreator" class="my-1 t-500-13 color11">
      ({{ $t('account_operations.created_by', {
        firstName: task.operationRef.creator_data.first_name, lastName:
          task.operationRef.creator_data.last_name
      }) }})
    </div>
    <div v-if="showReporter" class="t-500-13">
      <div class="color5 mt-1">
        <v-icon>
          today
        </v-icon>
        {{ task.operationRef.reportedDate }} {{ task.operationRef.reportedTime }}
      </div>
      <span class="color11">
        {{ $t('account_operations.done_by', {
          first_name: task.operationRef.reporter_first_name, last_name:
            task.operationRef.reporter_last_name
        }) }}
      </span>
    </div>
  </div>
</template>
<script>
import useAccount from '@/composables/useAccount.js'

import { frequencyTextFromTask, calculateCurrentStartPeriodForEveryXWeeksFrequency, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { ISO8601StringToDate, getClientDateFormatted, translateDayFromDate } from '@/helpers/DateTime.js'
import { isObject, isString } from '@/helpers/Utils.js'
import { i18n } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    crossAccountView: {
      type: Boolean,
      default: false
    },
    hqView: {
      type: Boolean,
      default: false
    },
    clientDate: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { accountCountryCode } = useAccount()
    // Computed
    const opRef = computed(() => props.task.operationRef)
    const showJobDepartment = computed(() => props.hqView && isString(opRef.value.account_job_department_name) && opRef.value.account_job_department_name.length > 0)
    const isReported = computed(() => props.task.isReported())
    const showCreator = computed(() => !props.crossAccountView && isObject(opRef.value.creator_data))
    const showReporter = computed(() => isReported.value && isString(opRef.value.reporter_first_name))
    const frequencyText = computed(() => {
      const task = opRef.value
      return frequencyTextFromTask({
        task,
        dateString: task.first_scheduled_date
      })
    })
    const showEveryXWeeksStartPeriod = computed(() => {
      const task = opRef.value
      if (task.frequency_type !== FrequencyTypes.EveryXWeek) return false

      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const clientDate = ISO8601StringToDate(props.clientDate)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, clientDate)
      return startPeriod.getTime() !== clientDate.getTime()
    })

    const startPeriodForEveryXWeeksFrequnecy = computed(() => {
      const task = opRef.value
      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const clientDate = ISO8601StringToDate(props.clientDate)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, clientDate)

      return i18n.t('operation_frequency.week_x_frequency_start_period', {
        date: getClientDateFormatted({ fromDate: startPeriod, countryCode: accountCountryCode.value }),
        day: translateDayFromDate(startPeriod)
      })
    })
    return {
      showCreator,
      showReporter,
      frequencyText,
      showEveryXWeeksStartPeriod,
      startPeriodForEveryXWeeksFrequnecy,
      showJobDepartment
    }
  }
}
</script>
